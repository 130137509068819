<template>
    <div @click="hideinfo" style="position: relative;">
        <div :class="[hover && 'shn', !khover && 'hka']" class="morenot" id="epnav" v-if="notifs && notifs.length > 0">
            <li class="notifhead">
                <router-link @click="killHover" :to="{ name: 'notifications' }">
                    All Notifications
                </router-link>
            </li>
            <div class="ncont">
                <li v-for="i in notifs" :key="i.id">
                    <router-link v-if="!i.cm" @click="killHoverAndSeen" ref="seenNote" :id="i.id"
                        :to="{ name: 'singlePost', params: { id: i.ref_id } }" :key="$route.path">
                        {{ ShortenWords(i.message) }}
                    </router-link>
                    <router-link v-else @click="killHoverAndSeen" ref="seenNote" :id="i.id"
                        :to="{ name: 'PostToComment', params: { id: i.ref_id, cm: i.cm } }"
                        :key="'PostToComment_' + i.ref_id">
                        {{ ShortenWords(i.message) }}
                    </router-link>
                    <svg v-if="!i.seen" width="8px" height="8px" viewBox="0 0 72 72" id="emoji"
                        xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <g id="color">
                                <circle cx="36" cy="36.0001" r="28" fill="#d22f27"></circle>
                            </g>
                            <g id="line">
                                <circle cx="36" cy="36.0001" r="28" fill="none" stroke="#000000" stroke-linejoin="round"
                                    stroke-width="2"></circle>
                            </g>
                        </g>
                    </svg>
                </li>
            </div>

        </div>
        <div v-else :class="[hover && 'shn', !khover && 'hka']" class="morenot nontif" id="epnav">no notifications</div>
        <span @click="activateHover" class="nalert">
            <svg v-if="user_alert" width="20px" height="20px" viewBox="0 0 24.00 24.00"
                id="_24x24_On_Light_Notification-Alert" data-name="24x24/On Light/Notification-Alert"
                xmlns="http://www.w3.org/2000/svg" fill="#ffffff" stroke="#ffffff"
                stroke-width="0.00024000000000000003">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <rect id="view-box" width="24" height="24" fill="none"></rect>
                    <path id="Shape"
                        d="M6,17v-.5H2.25A2.253,2.253,0,0,1,0,14.25v-.382a2.542,2.542,0,0,1,1.415-2.289A1.247,1.247,0,0,0,2.1,10.572l.446-4.91A6.227,6.227,0,0,1,10.618.286a5.477,5.477,0,0,0-.635,1.374A4.794,4.794,0,0,0,8.75,1.5,4.7,4.7,0,0,0,4.045,5.8L3.6,10.708A2.739,2.739,0,0,1,2.089,12.92a1.055,1.055,0,0,0-.589.949v.382A.751.751,0,0,0,2.25,15h13A.751.751,0,0,0,16,14.25v-.382a1.053,1.053,0,0,0-.586-.948A2.739,2.739,0,0,1,13.9,10.708l-.2-2.18a5.473,5.473,0,0,0,1.526.221l.166,1.822a1.26,1.26,0,0,0,.686,1.005,2.547,2.547,0,0,1,1.418,2.29v.382a2.252,2.252,0,0,1-2.25,2.25H11.5V17A2.75,2.75,0,0,1,6,17Zm1.5,0A1.25,1.25,0,0,0,10,17v-.5H7.5ZM15.047,6.744A3.486,3.486,0,0,1,13.5,6.28L13.456,5.8a4.7,4.7,0,0,0-1.648-3.185,3.5,3.5,0,0,1,.61-1.417A6.221,6.221,0,0,1,14.95,5.662l.1,1.081v0Z"
                        transform="translate(3.25 2.25)" fill="#ffffff"></path>
                    <path id="Shape-2" data-name="Shape" d="M3.5,7A3.5,3.5,0,1,1,7,3.5,3.5,3.5,0,0,1,3.5,7Z"
                        transform="translate(15 2)" fill="#ff6359"></path>
                </g>
            </svg>
            <svg v-else width="20px" height="20px" viewBox="0 0 24 24" id="_24x24_On_Light_Notification"
                data-name="24x24/On Light/Notification" xmlns="http://www.w3.org/2000/svg" fill="#ffffff">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <rect id="view-box" width="24" height="24" fill="none"></rect>
                    <path id="Shape"
                        d="M6,17v-.5H2.25A2.253,2.253,0,0,1,0,14.25v-.382a2.542,2.542,0,0,1,1.415-2.289A1.248,1.248,0,0,0,2.1,10.572l.446-4.91a6.225,6.225,0,0,1,12.4,0l.446,4.91a1.26,1.26,0,0,0,.686,1.005,2.547,2.547,0,0,1,1.418,2.29v.382a2.252,2.252,0,0,1-2.25,2.25H11.5V17A2.75,2.75,0,0,1,6,17Zm1.5,0A1.25,1.25,0,0,0,10,17v-.5H7.5ZM4.045,5.8,3.6,10.708A2.738,2.738,0,0,1,2.089,12.92a1.055,1.055,0,0,0-.589.949v.382A.751.751,0,0,0,2.25,15h13A.751.751,0,0,0,16,14.25v-.382a1.054,1.054,0,0,0-.586-.948A2.739,2.739,0,0,1,13.9,10.708L13.456,5.8a4.725,4.725,0,0,0-9.411,0Z"
                        transform="translate(3.25 2.25)" fill="#ffffff"></path>
                </g>
            </svg>
        </span>
    </div>
</template>

<script>
export default {
    name: 'NotificationButton',
    props: ['parentCommand'],
    inject: ['backendServer', 'user_alert', 'currentHost', 'doUpdateUserInfo'],
    data() {
        return {
            notifs: null,
            hover: false,
            khover: true,

        }
    },
    methods: {
        ShortenWords(string) {

            let editable = string.split('"')[1]
            if (editable.length > 20) {
                string = `${string.split('"')[0]} "${editable.substring(0, 18)}..." `
            }
            return string
        },
        killHover() {
            this.khover = false
        },
        killHoverAndSeen(event) {
            this.khover = false
            this.noteSeen(event)
        },
        activateHover(event) {
            event.stopPropagation();
            this.$emit('closeBox')

            this.khover = true;
            this.hover = !this.hover;

            document.addEventListener('click', event => {
                if (!event.target.closest('#epnav')) {
                    this.deactivateHover()
                }
            });
        },
        deactivateHover() {
            this.khover = true;

            this.hover = false;
        },
        fetchButton() {
            fetch(this.backendServer() + '/user-notification', {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.notifs = data

                });


        },
        noteSeen(event) {
            let id = event.target.id

            fetch(this.backendServer() + '/user-notification-seen/' + id, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.notifs) {
                        this.notifs = data.notifs

                        this.doUpdateUserInfo(data.base)
                    } else {
                        this.notifs = data
                    }

                });


        }

    },
    watch: {
        user_alert: function () { // watch it
            this.fetchButton()
        },
        parentCommand() {
            this.deactivateHover()
        },

    },

    mounted() {
        this.fetchButton()
    }

}
</script>

<style>
.nontif {
    text-align: center;
    height: auto !important;
}

li.notifhead {
    font-size: 1.2em !important;
    background-color: rgb(225, 225, 225);
    transition: .3s linear all;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 3px !important;
    text-align: center !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0;
}

li.notifhead:hover {
    background-color: white;
}

li.notifhead a {
    color: rgb(0, 0, 0) !important;
    transition: .3s linear all;

}

li.notifhead a:hover {
    color: rgb(255, 60, 60) !important;
}

span.nalert {
    margin-right: 2px;
    cursor: pointer;
}

.morenot {
    background: rgb(27 14 35 / 92%);
    height: 160px;
    width: 150px;
    right: -50px;
    top: 29px;
    border-radius: 10px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: .3s linear opacity;
}

.hka {
    visibility: hidden !important;
    opacity: 0 !important;
    transition: .3s linear opacity;

}

/* 
.morenot:hover {
    visibility: visible;
    opacity: 1;

} */

.morenot li {
    list-style: none;
    font-size: inherit;
    text-align: left;
    border-bottom: 1px #444444 solid;
    padding: 1px 5px;
}

.morenot li:last-child {
    border-bottom: none;
}

.morenot li a {
    font-size: .8em;
    color: white;
    font-family: monospace;
    transition: .3s linear all;
    margin-left: 0 !important;
}

.morenot li a:hover {
    color: #e3ff8a
}

.shn {
    visibility: visible !important;
    opacity: 1;
}

.mar {
    transition: .3s linear all;
    cursor: pointer;
}

.mar:hover {
    color: red;
}

.ncont {
    height: 132px;
    overflow: auto;
}
</style>