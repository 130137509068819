<template>
    <main class="admin">
        <nav>
            <li @click="changeBar('results')" :class="{ 'active_bar': nav === 'results' }">results</li>
            <li @click="changeBar('news')" :class="{ 'active_bar': nav === 'news' }">news</li>
            <li @click="changeBar('squad')" :class="{ 'active_bar': nav === 'squad' }">squad</li>
            <li @click="changeBar('motm')" :class="{ 'active_bar': nav === 'motm' }">motm</li>
        </nav>
        <div></div>
        <AdminNews v-if="nav === 'news'" />
        <AddPlayer v-if="nav === 'squad'" />

        <ResultVue v-if="nav === 'results'" />
        <MotmVue v-if="nav === 'motm'" />

    </main>
</template>

<script>
import ResultVue from '@/components/admin/ResultVue.vue'
import AdminNews from '@/components/admin/AdminNews.vue'
import AddPlayer from '@/components/admin/AddPlayer.vue'
import MotmVue from '@/components/admin/MotmVue.vue'
export default {
    name: 'AdminsVue',
    components: { ResultVue, AdminNews, AddPlayer, MotmVue },

    data() {
        return {
            nav: 'results',
        }
    },
    methods: {
        changeBar(word) {
            this.nav = word
        }
    }

}
</script>

<style scoped>
.active_bar {
    color: #ff4949 !important;
    font-weight: bold;
    pointer-events: none
}

nav {
    border-radius: 14px;
    width: 60%;
}

nav li {
    width: 50%;
    cursor: pointer;
}

main.admin {
    display: flex;
    align-items: center;
    flex-flow: column;
    color: azure;
}

main.admin nav {
    position: relative;
    z-index: 2;
}

main.admin div {
    margin-top: 100px
}

::v-deep(input,
    select) {
    border-radius: 15px !important;
    text-align: center !important;
}
</style>