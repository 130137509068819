<template>
    <div class="player-list">
        <h2 v-if="!message">Select Players (Match {{ match }})</h2>
        <p v-if="message" class="error-message">{{ message }}</p> <!-- Show message if exists -->

        <ul v-if="!message">
            <li v-for="player in players" :key="player">
                <label>
                    <input type="checkbox" v-model="selectedPlayers" :value="player" />
                    {{ player }}
                </label>
            </li>
        </ul>

        <button v-if="!message" @click="sendSelectedPlayers">Send Selected Players</button>
    </div>
</template>


<script>
export default {
    name: 'MotmVue',
    inject: ['backendServer', 'currentHost', 'setFlashedMessage', 'loading'],
    data() {
        return {
            message: '',
            match: '',
            players: [],
            selectedPlayers: [],
        };
    },
    methods: {

        sendSelectedPlayers() {

            this.loading()
            fetch(this.backendServer() + '/motm-players-submit', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({ players: this.selectedPlayers }),
            }).then((response) => {
                return response.json();
            }).then((data) => {
                this.loading();
                this.message = 'already done'
                this.setFlashedMessage(data);
            })
        },

    },
    mounted() {
        fetch(this.backendServer() + '/motm-players', {
            method: "GET",
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    this.message = data.message;  // Store the message
                } else {
                    this.players = data.players;
                    this.match = data.match;
                }
            })
            .catch(error => console.error('Error fetching players:', error));
    }

}
</script>

<style scoped>
.player-list {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    max-width: 300px;
    margin-top: 0px !important
}

ul {
    list-style: none;
    padding: 0;
}

li {
    margin: 5px 0;
}

button {
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
}
</style>