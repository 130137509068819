<template>
    <div v-if="media && media.length != 3" :class="media.length != 1 && 'pmediac'">
        <span v-for="m in media" :key="m.type" class="icpme" :class="media.length == 1 && 'icpme1'">
            <img v-if="m.type == 'image'" @click="zoomImage(m.file)" :class="imageClass(media.length)" :src="m.file"
                alt="">
            <video v-else :class="imageClass(media.length)" controls :preload="preload && none">
                <source :src="m.file" type="video/mp4">

            </video>
            <svg v-if="m.type == 'image'" @click="zoomImage(m.file)" xmlns="http://www.w3.org/2000/svg" width="50"
                height="50" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0" />
                <path
                    d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
                <path fill-rule="evenodd"
                    d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5" />
            </svg>
        </span>
    </div>
    <div v-if="media && media.length == 3" class="pmediacw" :class="height && 'pmediacwe'">
        <span class="icpme3">
            <img v-if="media[0].type == 'image'" @click="zoomImage(media[0].file)" class="post_image_container4"
                :src="media[0].file" :class="[lheight && 'smallpc', height && 'lessh4']" alt="">
            <video v-else class="post_image_container4" controls>
                <source :src="media[0].file" :class="lheight && 'smallpc'" type="video/mp4">

            </video>
            <svg v-if="media[0].type == 'image'" :class="[lheight && 'smallpc', height && 'lessh4']"
                @click="zoomImage(media[0].file)" xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0" />
                <path
                    d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
                <path fill-rule="evenodd"
                    d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5" />
            </svg>
        </span>
        <div class="icpme4">
            <span class="s1">
                <img v-if="media[1].type == 'image'" @click="zoomImage(media[1].file)" :class="imageClass(media.length)"
                    :src="media[1].file" alt="">
                <video v-else :class="imageClass(media.length)" controls>
                    <source :src="media[1].file" type="video/mp4">

                </video>
                <svg v-if="media[1].type == 'image'" @click="zoomImage(media[1].file)"
                    xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-zoom-in"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0" />
                    <path
                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
                    <path fill-rule="evenodd"
                        d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5" />
                </svg>
            </span>
            <span class="s2">
                <img v-if="media[2].type == 'image'" @click="zoomImage(media[2].file)" :class="imageClass(media.length)"
                    :src="media[2].file" alt="">
                <video v-else :class="imageClass(media.length)" controls>
                    <source :src="media[2].file" type="video/mp4">

                </video>
                <svg v-if="media[2].type == 'image'" @click="zoomImage(media[2].file)"
                    xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-zoom-in"
                    viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11M13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0" />
                    <path
                        d="M10.344 11.742q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1 6.5 6.5 0 0 1-1.398 1.4z" />
                    <path fill-rule="evenodd"
                        d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5" />
                </svg>
            </span>

        </div>

    </div>
</template>

<script>
// import VueImageZoomer from 'vue-image-zoomer';
export default {
    name: 'PostMedia',
    props: ['media', 'preload', 'height', 'lheight'],
    inject: ['zoomer'],
    methods: {
        zoomImage(src, event) {
            this.zoomer(src)
            if (event) {
                event.stopPropagation();


            }
        },
        imageClass(len) {
            let extra = ''
            if (this.height && len == 1) {
                extra = ' lessh'
            }
            if (this.height && len == 2) {
                extra = ' lessh4'
            }
            if (this.height && len == 4) {
                extra = ' lessh5'
            }
            if (this.height && len == 3) {
                extra = ' lessh6'
            }
            if (this.lheight && len != 3) {
                extra = ' lessh1'
            }
            if (this.lheight && len == 3) {
                extra = ' lessh3'
            }
            if (len < 2) {
                return 'post_image_container1' + extra
            } else if (len == 2) {
                return 'post_image_container2' + extra
            } else if (len == 3) {
                return 'post_image_container3' + extra
            } else if (len > 3) {
                return 'post_image_container' + extra
            }
        }
    }
}
</script>

<style>
.pmediacw {
    display: flex;
    gap: 6px;
    justify-content: center;
}

.pmediacwe {
    margin-bottom: 5px
}

.icpme3 {
    width: 45%;
    position: relative;
}

.smallpc {
    height: 120px !important;
}

.icpme4 {
    width: 45%;
    display: grid;
    gap: 6px;
}

.icpme4 span {
    position: relative;
}

span .lessh {
    height: 173px !important;
    max-width: 80% !important;
}

span .lessh4 {
    height: 185px !important;
}

span .lessh5 {
    height: 90px !important;
}

span .lessh1 {
    height: 119px !important;
    margin: 5px 0
}

span .lessh3 {
    height: 57px !important;
}

span .lessh6 {
    height: 89.5px !important;
}


.bi-zoom-in {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: .4s linear opacity;
    color: white !important;
    cursor: pointer;
}

.icpme:hover .bi-zoom-in,
.icpme3:hover .bi-zoom-in,
.icpme4 span:hover .bi-zoom-in {
    opacity: 1;
}

.icpme1 {
    width: 100% !important;
    display: block;
    text-align: center;
}

.pmediac {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
}

.pbvcon {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}

.icpme {
    width: 45%;
    position: relative;
}

.post_image_container {
    width: 100%;
    height: 250px;
    object-fit: contain;
    border-radius: 10px;
    cursor: pointer;
    background: black;

}

.post_image_container1 {
    object-fit: contain;
    max-width: 100%;
    border-radius: 10px;
    cursor: pointer;
    background: black;
    margin-bottom: 5px;
}

.post_image_container2 {
    object-fit: contain;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    height: 250px;
    background: black;

}

.post_image_container3 {
    object-fit: contain;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    height: 247px;
    background: black;

}

.post_image_container4 {
    object-fit: contain;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    height: 500px;
    background: black;
}

@media (max-width: 820px) {

    .post_image_container,
    .post_image_container2 {
        height: 150px;
        /* When the viewport width is 1024px or larger, set the height to 20% */
    }

    .post_image_container4 {
        height: 300px;
    }

    .post_image_container3 {
        height: 147px;
    }
}

@media (max-width: 450px) {

    .post_image_container,
    .post_image_container2 {
        height: 95px;
        /* When the viewport width is 1024px or larger, set the height to 20% */
    }

    .post_image_container4 {
        height: 190px;
    }

    .post_image_container3 {
        height: 92px;
    }
}
</style>