<template>
    <div class="player-form">
        <h2>Add Player</h2>
        <ImageFinder @addPicture="changePic" />

        <form @submit.prevent="submitPlayer">

            <label>Name</label>
            <input type="text" v-model="player.name" required />

            <label>Height</label>
            <input type="text" v-model="player.height" required />

            <label>Joined</label>
            <input type="text" v-model="player.joined" required />

            <label>Contract</label>
            <input type="text" v-model="player.contract" required />

            <label>Birthday</label>
            <input type="text" v-model="player.birthday" required />

            <label>Position</label>
            <input type="text" v-model="player.position" required />
            <label>Number</label>
            <input type="number" v-model="player.number" required />

            <label>Nationality</label>
            <input type="text" v-model="player.nationality" required />
            <!-- <label>picture</label>
            <label>Logo (SVG URL or Raw SVG String)</label>
            <textarea v-model="player.logo" placeholder="Paste SVG URL or SVG content here"></textarea> -->

            <button type="submit">Submit Player</button>

        </form>
    </div>
</template>

<script>
import ImageFinder from './ImageFinder.vue';
export default {
    data() {
        return {
            chosen_pic: null,

            player: {
                number: '',
                img: '',
                name: '',
                height: '',
                joined: '',
                contract: '',
                birthday: '',
                position: '',
                nationality: '',
                // logo: ''  // This will store either an SVG URL or the raw SVG itself
            }
        };
    },

    inject: ['backendServer', 'currentHost', 'loading', 'setFlashedMessage'],
    components: { ImageFinder },
    methods: {
        changePic(target) {
            this.chosen_pic = target

        },
        async submitPlayer() {
            const payload = {
                name: this.player.name,
                height: this.player.height,
                joined: this.player.joined,
                contract: this.player.contract,
                birthday: this.player.birthday,
                position: this.player.position,
                nationality: this.player.nationality,
                // logo: this.player.logo,  // this can be an SVG string or a URL
                img: this.chosen_pic,
                number: this.player.number

            };
            this.loading()
            fetch(this.backendServer() + '/add_player', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(payload),
            }).then((response) => {
                return response.json();
            }).then((data) => {
                this.chosen_pic = null
                this.resetForm()
                this.loading()
                this.setFlashedMessage(data);
            })

        },
        resetForm() {
            this.player = {
                name: '',
                height: '',
                joined: '',
                contract: '',
                birthday: '',
                position: '',
                nationality: '',
                // logo: ''
            };
        },
        // backendServer() {
        //     return 'https://your-backend-url.com';  // Replace with your actual backend URL
        // }
    }
};
</script>

<style scoped>
.player-form {
    max-width: 500px;
    margin: 8px auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

label {
    font-weight: bold;
}

div.player-form {
    margin: 2px !important
}

input,
textarea,
button {
    padding: 8px;
    font-size: 12px;
    width: 100%;
    box-sizing: border-box;
}

textarea {
    height: 32px;
    resize: vertical;
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
</style>
