<template>
    <input type="text" ref="search" placeholder="name">
    <button @click="imageFinder()">find</button>
    <div class="image_finder_container">
        <div v-for="image in images" :key="image.name">
            <SingleImage :image="image" :value="$refs.search.value" />
        </div>
    </div>
</template>

<script>
import SingleImage from './SingleImage.vue';
export default {
    name: 'ImageFinder',
    inject: ['backendServer', 'currentHost', 'loading'],
    emits: ['addPicture'],
    components: { SingleImage },
    data() {
        return {
            chosen: null,
            images: null
        }
    },

    methods: {
        imageFinder() {
            this.loading()
            fetch(this.backendServer() + '/image-finder/' + this.$refs.search.value, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    this.images = data;
                    this.loading()
                });
        },

    },


}
</script>

<style scoped>
input {
    width: 200px;
}

.image_finder_container {
    display: flex;
    flex-wrap: wrap
}



img {
    width: 75px;
    height: 75px;
    object-fit: contain;
}
</style>