<template>
    <div class="all_matches">


        <div class="comp_title">
            <h6 class="league-info-header">{{ title }}</h6>
            <div>
                <select v-model="selectedOption" @change="sorting" name="" id="" class="year_options">
                    <option v-for="week in weeks" :key="week" :value="week">{{ changeStage(week) }}</option>
                </select>
            </div>
        </div>
        <div v-for="(result, index) in results" :key="index" class="single_match"
            :class="result.home_score != -1 && 'available-result'" @click="openResult($event, result.home_score)"
            :id="result.id">
            <span class="home_team">{{ result.home }} <img class="team_results_logo" :src="result.home_logo"
                    alt=""></span>
            <span v-if="!result.date.includes('within')" class="match_date">{{ result.date }}</span>
            <div v-if="result.home_score != -1" class="match_result" :id="result.id">
                <span class="home_result">
                    {{ result.home_score }}
                </span><span> - </span>
                <span class="away_result">
                    {{ result.away_score }}
                </span>
            </div>
            <div v-else class="match_result">
                {{ result.time != '00:00' ? result.time : 'TBD' }}
            </div>


            <span class="away_team"> <img class="team_results_logo" :src="result.away_logo" alt=""> {{ result.away
            }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'uclWeeklyResult',
    inject: ['backendServer', 'currentHost', 'loading'],
    props: ['currentYear'],
    data() {
        return {
            results: null,
            week: 0,
            weeks: null,
            option: null,
            selectedOption: 6,
            title: 'Matchday',
            openedWindows: {}
        }
    },
    methods: {

        openResult(event, res) {
            const targetDivId = event.target.closest('div').id;

            if (this.openedWindows[targetDivId]) {
                const existingWindow = this.openedWindows[targetDivId];

                // Check if the window is closed or not focused
                if (!existingWindow.closed) {
                    existingWindow.focus();
                    return;
                }
            }

            if (res === -1) {
                return;
            }

            const width = 500;
            const height = 800;
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            const newWindow = window.open(`/comp/ucl/results/${targetDivId}`, '_blank', `width=${width}, height=${height}, left=${left}, top=${top}`);

            if (newWindow) {
                this.openedWindows[targetDivId] = newWindow;

                newWindow.addEventListener('beforeunload', () => {
                    delete this.openedWindows[targetDivId];
                });
            }
        },

        uclWeeklyResult() {
            this.loading()

            fetch(this.backendServer() + '/ucl-weeks/' + this.week, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {

                    this.results = data.results;
                    this.weeks = data.weeks
                    this.option = data.option
                    // this.title = 'matchday ' + this.option
                    this.selectedOption = this.option
                    this.loading()
                });
        },
        sorting(event) {
            this.week = event.target.value
            // this.week = this.changeStage(this.week)
            this.uclWeeklyResult()
        },
        changeStage(name) {
            switch (name) {
                case 9:
                    return '1/16'
                case 10:
                    return '1/8';
                case 11:
                    return 'Quarter Final';
                case 12:
                    return 'Semi Final';
                case 13:
                    return 'Final';
                case '9':
                    return '1/16'
                case '10':
                    return '1/8';
                case '11':
                    return 'Quarter Final';
                case '12':
                    return 'Semi Final';
                case '13':
                    return 'Final';
                case '1/16':
                    return 9;
                case '1/8':
                    return 10;
                case 'Quarter Final':
                    return 11;
                case 'Semi Final':
                    return 12;
                case 'Final':
                    return 13;
                default:
                    return name;
            }
        }
    },


    mounted() {

        this.uclWeeklyResult()
    },
    // watch: {
    //     currentYear: function (newVal, oldVal) { // watch it
    //         
    //         this.week = 0
    //         this.weeklyResults()
    //         if (newVal != '2023-24')
    //             this.title = 'Latest Results'
    //         if (newVal == '2023-24')
    //             this.title = 'Current Matchday'
    //     }

    // }
}
</script>
<style></style>