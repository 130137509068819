<template>
    <div class="squad-players">

        <div v-for="(player, index) in playerss" :key="index" class="squad-player">
            <div class="ianc">
                <img class="playerpic" :src="player.img" alt="">
                <span class="name">{{ player.name }}</span>
            </div>
            <div class="p-info">
                <p><span>Age</span> : <b> {{ player.age }} years old</b></p>

                <p><span>Nationality</span> : <b> {{ player.nationality }}</b> <img class="nl" :src="player.nation_logo"
                        alt=""></p>
                <p><span>Contract</span> : <b> {{ player.contract }}</b></p>

                <p><span>Position</span> : <b>{{ player.position }}</b></p>
                <p><span>Height</span> : <b>{{ player.height }} cm</b></p>
                <p><span>{{ ordinalMaker(player.seasons) }}</span><b> season at the club</b></p>

            </div>
            <img class="shirt" :src="shirt" alt="">
            <span class="number">{{ player.num }}</span>
        </div>
    </div>
</template>

<script>
import shirt from '@/assets/img/dfrg.png'
export default {
    inject: ['backendServer', 'currentHost'],
    props: ['players'],
    data() {
        return {
            // playerss: null,
            shirt: shirt
        }
    },
    methods: {
        ordinalMaker(num) {
            if (num == 0)
                return (num + 1) + 'st'
            else if (num == 1)
                return (num) + 'st'
            else if (num == 2)
                return (num) + 'nd'
            else if (num == 3)
                return (num) + 'rd'
            else
                return (num) + 'th'
        }
    },
    computed: {
        playerss() {
            return this.players;
        }
    }
}
</script>

<style>
:root {
    --max-font-width: 80px;
}

.p-info {
    text-align: left;
    color: white;
    font-size: .72em;
    padding: 8px;
    margin-top: 98px;
}

.p-info p span {
    color: tomato
}

.p-info p {
    margin-bottom: 0;
}

.name {
    font-family: monospace;
    font-size: .85em
}

.squad-player img.nl {
    /* width: 18px; */
    width: 20px;
    border-radius: 4px;
    background: none;
}

span.number {
    position: absolute;
    right: 10.6px;
    text-align: center;
    bottom: 18px;
    color: black;
    font-family: monospace;
    min-width: 30px;
}


.squad-player img.shirt {
    width: 43px;
    height: 43px;
    position: absolute;
    right: 4px;
    bottom: 6px;
}

.ianc {
    display: grid;
    justify-items: center;
    position: absolute;
    left: 50%;
    margin-bottom: 6px;
    transform: translate(-50%, -50%);
    color: #f5f5f5;
}

.squad-player img.playerpic {
    width: 175px;
    height: 175px;
    object-fit: cover;
    border: 3px solid rgb(26 20 20);
    border-radius: 50%;
    background: #f5f5f5;
}

.squad-players {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.squad-player {
    background: rgb(27 14 35 / 80%);
    width: 175px;
    height: 225px;
    border-radius: 7px;
    margin: 52px 5px;
    position: relative;
}
</style>