<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo :article="article" />
        </article>
        <LeagueLogo logo="ucl" />
        <div :class="$route.path.includes('/ucl') && 'sub_container_small'" class="sub_container_b">
            <NewsSubMenu name="Info" route="uclinfo" />
            <NewsSubMenu name="tables" route="uclstages" />
            <NewsSubMenu name="Matchday" route="uclmatchday" />
            <NewsSubMenu name="Goal-Scorers" route="uclgs" />
        </div>
        <div class="comp_title comp_title_matchday">
            <h5 class="league-info-header">{{ title }}</h5>
            <select v-model="selectedOption" @change="sorting" name="" id="" class="year_options">
                <option v-for="week in weeks" :key="week" :value="week">{{ changeStage(week) }}</option>
            </select>
        </div>
        <ul class="league_info matchday fixtures">
            <TeamResult v-for="(game, index) in done" :key="week + '-' + index" :game="game" :reset="reset" />
            <FixturesVue v-for="(game, index) in undone" :key="index" :game="game" />
        </ul>

    </main>
</template>

<script>

import ArticleLogo from '../components/ArticleLogo.vue'
import FixturesVue from '@/components/team/FixturesVue.vue'
import TeamResult from '@/components/team/TeamResult.vue'
import LeagueLogo from '@/components/LeagueLogo.vue'
import NewsSubMenu from '@/components/team/NewsSubMenu.vue'

export default {
    name: 'UclMatchDay',
    components: { ArticleLogo, FixturesVue, TeamResult, LeagueLogo, NewsSubMenu },
    inject: ['backendServer', 'loading', 'currentHost'],
    data() {
        return {
            fullScreen: true,
            reset: false,
            article: 'News',
            limit: undefined,
            activeMenu: 'serie A',
            activeSubMenu: 'match day',
            currentYear: 'default',
            results: null,
            week: 0,
            weeks: null,
            option: null,
            selectedOption: 38,
            title: 'Matchday',
            done: null,
            undone: null

        }
    },
    methods: {
        changeStage(name) {
            switch (name) {
                case 9:
                    return '1/16'
                case 10:
                    return '1/8';
                case 11:
                    return 'Quarter Finals';
                case 12:
                    return 'Semi Final';
                case 13:
                    return 'Final';
                case '9':
                    return '1/16'
                case '10':
                    return '1/8';
                case '11':
                    return 'Quarter Finals';
                case '12':
                    return 'Semi Final';
                case '13':
                    return 'Final';
                case '1/16':
                    return 9;
                case '1/8':
                    return 10;
                case 'Quarter Finals':
                    return 11;
                case 'Semi Final':
                    return 12;
                case 'Final':
                    return 13;
                default:
                    return name;
            }
        },
        sorting(event) {

            this.weeklyResults(event.target.value)
        },
        weeklyResults(newWeek = 0) {
            this.loading()
            // newWeek = changeStage(newWeek)

            fetch(this.backendServer() + '/all-cl-league-results/' + newWeek, {
                method: "GET",
                credentials: "include"
            })
                .then((response) => response.json())
                .then((data) => {
                    this.week = newWeek
                    this.done = data.done;
                    this.undone = data.undone;
                    this.weeks = data.weeks
                    this.option = data.option
                    this.selectedOption = this.option
                    this.loading()
                });
        },
    },
    mounted() {
        document.title = 'UCL Matchday / Rossoneries';

        this.weeklyResults()

    },

}
</script>

<style></style>