<template>
    <main class="news all_posts">

        <article class="news news2">
            <ArticleLogo />
        </article>
        <ul class=" fixtures">
            <TeamResult v-for="(game, index) in games" :key="index" :game="game" :screen="screen" />
        </ul>
        <ul class="squad">
            <SquadPlayers :players="players" />
        </ul>

    </main>
</template>

<script>
import ArticleLogo from '@/components/ArticleLogo.vue'
import TeamResult from '@/components/team/TeamResult.vue'
import SquadPlayers from '@/components/team/SquadPlayers.vue'
// import NewsSubMenu from '@/components/team/NewsSubMenu.vue'

export default {
    name: 'MilanFixtures',
    components: { ArticleLogo, TeamResult, SquadPlayers },
    data() {
        return {
            games: null,
            screen: true,
            players: null,

        }
    },
    inject: ['backendServer', 'currentHost'],

    methods: {
        activeMenuChange(event) {
            this.activeMenu = String(event)

        },
        activeSubMenuChange(event) {
            this.activeSubMenu = String(event)

        }
    },
    mounted() {

        fetch(this.backendServer() + '/motm-game', {
            method: "GET",
            credentials: "include"
        })
            .then((response) => response.json())
            .then((data) => {
                this.games = data.match;
                this.players = data.players

            });

    }

}
</script>

<style></style>